#view_button{
    border: 1px solid rgb(85, 255, 0);
    background-color: rgb(85, 255, 0);
    
}
#save_button{
    border: 1px solid rgb(85, 255, 0);
    background-color: rgb(85, 255, 0);
    
}
#backbtn{
    background-color: black;
    color: white;
}